/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Man rodos, kad dažniausiai aš neturiu ką pasakyt, ir mano tikslas\npasakojant dalykus yra tave sužavėti. Pakerėt žvilgsniu, parodyt grožį,\npaskleisti šypseną ir tikėtis jos atgal. Man rodos, kad mano tikslas\ntave sužavint yra to žavėjimo susilaukimas atgal. Tipo kur žavus žavų\nžavi. Kiek yra išvis žodžių iš „ž“?"), "\n", React.createElement(_components.p, null, "Ir pyzdiec kaip gražu yra žiūrėt kaip bigbendas daužo į langus su meilės\nlaišku viskam ", React.createElement(_components.em, null, "(žr. 1)"), ". Viską išnešt iš tos meilės! Skambesiui, žinai, ir\nakcentams. Man kažkaip atrodo, kad akcentai yra geriausias dalykas, koks\ngalėjo nutikti kalbai, ir tu jų taip paprastai nepažabosi."), "\n", React.createElement("aside", null, React.createElement("p", null, "1. Čia šiaip referensas į ", React.createElement("a", {
    href: "https://www.youtube.com/watch?v=mSntOlVWGeA"
  }, "dainą apie Kauną\nYouTube'ėj"), ", bet ką jau čia.")), "\n", React.createElement(_components.p, null, "Žavus, zaraza, žinai."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
